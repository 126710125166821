import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'

import Loading from './components/Loading'

const LoginPage = lazy(() => import('./pages/LoginPage'))
const RegisterPage = lazy(() => import('./pages/RegisterPage'))
const ProfilePage = lazy(() => import('./pages/ProfilePage'))
const PostPage = lazy(() => import('./pages/PostPage'))
const CreatePostPage = lazy(() => import('./pages/CreatePostPage'))
const CommunityPage = lazy(() => import('./pages/CommunityPage'))
const HomePage = lazy(() => import('./pages/HomePage'))
const KitchenPage = lazy(() => import('./pages/KitchenPage'))
const ActivationPage = lazy(() => import('./pages/ActivationPage'))
const BlogPage = lazy(() => import('./pages/BlogPage'))
const Membership = lazy(() => import('./pages/Membership'))
const Subscription = lazy(() => import('./components/Membership/StripeForm'))
const Terms = lazy(() => import('./pages/Terms'))
const Privacy = lazy(() => import('./pages/Privacy'))

const DraftRoute = withRouter((props) => (
  <div>
    {props.location.draftProps !== undefined ? (
      <Route path="/draft" render={() => <CreatePostPage />} exact />
    ) : (
      <Redirect to="/" />
    )}
    )
  </div>
))

const Routes = () => (
  <Suspense fallback={() => <Loading />}>
    <Switch>
      <Route path="/" render={() => <HomePage />} exact />
      <Route path="/profile" render={() => <ProfilePage />} exact />
      <Route path="/community" render={() => <CommunityPage />} exact />
      <Route path="/profile/:id" render={() => <ProfilePage />} exact />
      <Route path="/activate/:token" render={() => <ActivationPage />} exact />
      <Route path="/planner" render={() => <KitchenPage />} exact />
      <Route path="/story/create" render={() => <CreatePostPage />} exact />
      <Route path="/story/:id" render={() => <PostPage />} exact />
      <Route path="/storyworld" render={() => <BlogPage />} exact />
      <Route path="/membership" render={() => <Membership />} exact />
      <Route path="/subscription" render={() => <Subscription />} exact />
      <Route path="/terms" render={() => <Terms />} exact />
      <Route path="/privacy" render={() => <Privacy />} exact />
      <DraftRoute />
      <Redirect to="/" />
    </Switch>
  </Suspense>
)

export default Routes
